import type { IOffer } from '@bridebook/models/source/models/Suppliers/Offers.types';
import { mapDateToUI, mapToExactDate } from '@bridebook/toolbox/src';
import gazetteer, { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { getI18n } from 'lib/i18n/getI18n';
import { OfferDiscountTypes } from './constants';

export const getTimeLeft = (endDate: number): string | null => {
  // Maximum number of weeks to display the time left
  const MAX_WEEKS = 8;
  const i18n = getI18n();
  let res = '';

  if (!endDate || !Number.isInteger(endDate)) {
    return null;
  }

  const secondsLeft = Number(new Date(endDate)) - Date.now();
  // Math.ceil so we have at least 1 day left
  const days = Math.ceil(secondsLeft / 1000 / 60 / 60 / 24);
  const weeks = Math.round(days / 7);

  if (days < 0 || weeks > MAX_WEEKS) {
    return null;
  }

  if (days > 31) {
    res = `${weeks} ${i18n.t('offers:weeks', {
      count: weeks,
    })}`;
  } else {
    res = `${days} ${i18n.t('offers:days', {
      count: days,
    })}`;
  }

  return res;
};

/**
 * Returns true if a given date is in the future
 * @param date
 */
export const isDateInFuture = (date?: number) => !!date && new Date(date) > new Date();

export const convertLateDatesToTimestamps = (dates: IOffer['dates']) => {
  if (!Array.isArray(dates)) {
    return [];
  }

  /**
   * @TODO: [i18n] - remove hardcoded market.
   */
  const market = gazetteer.getMarketByCountry(CountryCodes.GB);

  return dates
    .map((d) => mapToExactDate(mapDateToUI(d), market))
    .filter(Boolean)
    .map((dateString) => new Date(dateString as string).valueOf());
};

/**
 * Returns tha date furthest in the future from an array of dates
 * @param lateDates - Array<number>
 */
export const getLatestDate = (dates?: IOffer['dates']) => {
  if (!Array.isArray(dates)) {
    return undefined;
  }

  const timestamps = convertLateDatesToTimestamps(dates);

  return Math.max(...timestamps);
};

/**
 * Returns discount string representation
 */
export const getDiscountLabel = (offer: IOffer, currency: string): string => {
  const { discountType = '', discount, title } = offer || {};
  const i18n = getI18n();

  switch (discountType) {
    case OfferDiscountTypes.percentOff:
      return i18n.t('offers:discountOff', { discount });

    case OfferDiscountTypes.absoluteOff:
      return i18n.t('offers:discountMoneyOff', {
        discount: currency + discount,
      });

    case OfferDiscountTypes.setValue:
      return i18n.t('offers:discountJust', {
        discount: currency + discount,
      });

    case OfferDiscountTypes.other:
      return title || '';

    default:
      return '';
  }
};
