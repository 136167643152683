import { IApplicationState } from 'lib/types';

export default function variationTest(
  getState: () => IApplicationState,
  force: boolean = false,
): string {
  const {
    app: {
      pathname,
      device: { host },
    },
  } = getState();
  let href = `http${host.indexOf('bridebook.co.uk') > -1 ? 's' : ''}://${host}${pathname}`;
  if (pathname !== '/' && !force) return href;
  href += href.indexOf('?') > -1 ? '&' : '?';
  return href;
}
