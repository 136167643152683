/* eslint-disable */
import type { firebase } from '../../firebase/firestore';

export type WeekDay = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';
export type Month = number;
export type Season = 'autumn' | 'spring' | 'summer' | 'winter';

export interface IOffer {
  /**
   * Automatically Generated Language Translations
   */
  _translations?: {
    [k: string]: IOffer_Translations;
  };
  active: boolean;
  createdAt: firebase.firestore.Timestamp;
  dates?: {
    d: number | WeekDay | null | WeekDay[];
    m: Month | Season | null | Month[];
    y: number | null;
  }[];
  /**
   * Translatable Field
   */
  details?: string;
  discount: number | string;
  discountType?: IOffer_DiscountType;
  expiration?: firebase.firestore.Timestamp;
  id: string;
  packages?: string[];
  /**
   * Translatable Field
   */
  title: string;
  type?: IOffer_Type;
  updatedAt?: firebase.firestore.Timestamp;
}
export interface IOffer_Translations {
  /**
   * Automatically Generated
   */
  details?: string;
  /**
   * Automatically Generated
   */
  title?: string;
}

export enum IOffer_DiscountType {
  absoluteOff = 'absoluteOff',
  other = 'other',
  percentOff = 'percentOff',
  setValue = 'setValue',
}
export enum IOffer_Type {
  bbDiscount = 'bbDiscount',
  custom = 'custom',
  lateAvailability = 'lateAvailability',
  midweek = 'midweek',
  seasonal = 'seasonal',
}
