import { has, isEmpty, omit, path, pathOr } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { getDatepickerTitle, mapDateToUI, mapToExactDate } from '@bridebook/toolbox/src';
import gazetteer, { CountryCodes, Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { selectMarketWithFallback } from 'lib/i18n/selectors';
import { getWeddingVenue } from 'lib/shortlist/selectors';
import { Action, IApplicationState, IDeps, IEpicDeps, UIDate } from 'lib/types';
import { UserActionTypes } from 'lib/users/action-types';
import { selectUserLocale } from 'lib/users/selectors';
import serverSideTrack from 'lib/utils/server-side-track';
import { userFailedToEditUserDetailsAnalytics } from 'lib/weddings/actions';
import { CriticalWebEvents } from '../analyticsTypes';
import { WeddingActionTypes } from './action-types';
import { getVenueBooked } from './selectors';
import { IProfile, IWeddingVenue } from './types';
import stripWeddingVenue from './utils/strip-wedding-venue';

const weddingLocationPropertiesGeneric = (
  getState: () => IApplicationState,
  payload: { countryCode: string },
): {
  previousWeddingLocation: string;
  newWeddingLocation: string;
} => {
  const {
    device: { serverCountryCode },
  } = getState().app;

  return {
    previousWeddingLocation: serverCountryCode || '',
    newWeddingLocation: payload.countryCode,
  };
};

export const profileAnalyticsListenerEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(
      UserActionTypes.UPDATE_USER_DETAILS_ERROR,
      WeddingActionTypes.UPDATE_WEDDING_FIELD_SUCCESS,
      WeddingActionTypes.UPDATE_WEDDING_FIELD_ERROR,
      'CHANGE_WEDDING_TYPE_SUCCESS',
      'CHANGE_WEDDING_TYPE_AND_SKIP',
      WeddingActionTypes.SAVE_WEDDING_DATE_SUCCESS,
      UserActionTypes.UPDATE_USER_FIELD,
      UserActionTypes.CHANGE_CORRESPONDENCE_EMAIL_SUCCESS,
    ),
    withLatestFrom(state$),
    mergeMap(([{ type, payload }, state]: [Action, IApplicationState]) => {
      const getWeddingProfileName = (): string => {
        const { name, value } = payload;
        switch (name) {
          case 'location':
            return 'weddingLocation';
          case 'numberOfGuests':
            return 'guests';
          case 'guestsInitialTargetUndecided':
            return 'guestsInitialUndecided';
          case 'budget':
            return value.budget === null ? 'budgetInitialUndecided' : name;
          default:
            return name;
        }
      };
      const isPartnerEdit = (): boolean => {
        const { name } = payload;
        return name === 'partners';
      };

      switch (true) {
        case type === UserActionTypes.CHANGE_CORRESPONDENCE_EMAIL_SUCCESS:
          return of({
            type: UserActionTypes.USER_EDITED_USER_DETAILS_ANALYTICS,
            payload: { name: 'userEmail', userEmail: payload.email },
          });
        case type === UserActionTypes.UPDATE_USER_FIELD: {
          let name = payload.name;
          let value = payload.value;
          const userLocale = selectUserLocale(state);

          if (name === 'contacts') {
            if (has('email', value)) {
              name = 'userEmail';
              value = value.email;
            } else if (has('phone', value)) {
              name = 'userPhone';
              value = value.phone;
            }
          } else if (name === 'photo') {
            name = 'userThumbnailUrl';
            value = value.path;
          } else if (name === 'l10n') {
            /**
             * This is currently treated in Mixpanel as the "user changed language" event.
             * Only send an analytics event if the locale actually has changed
             **/
            if (has('locale', value) && userLocale !== value.locale) {
              name = 'locale';
              value = value.locale;
            }
          }

          return of({
            type: UserActionTypes.USER_EDITED_USER_DETAILS_ANALYTICS,
            payload: {
              name,
              [name]: value,
              ...(payload.extraAnalytics?.userDetailsLocation && {
                userDetailsLocation: payload.extraAnalytics.userDetailsLocation,
              }),
            },
          });
        }

        case type === UserActionTypes.UPDATE_USER_DETAILS_ERROR: {
          return of(userFailedToEditUserDetailsAnalytics({ ...payload, name: payload.name }));
        }
        case type === WeddingActionTypes.UPDATE_WEDDING_FIELD_SUCCESS ||
          type === WeddingActionTypes.SAVE_WEDDING_DATE_SUCCESS:
          return of({
            type: isPartnerEdit()
              ? 'EDITED_WEDDING_PARTNER_NAMES_ANALYTICS'
              : 'EDITED_WEDDING_DETAILS_ANALYTICS',
            payload: {
              name: getWeddingProfileName(),
              ...(payload.extraAnalytics?.partnerEmail && {
                partnerEmail: payload.extraAnalytics.partnerEmail,
              }),
              ...(payload.extraAnalytics?.weddingDetailsLocation && {
                weddingDetailsLocation: payload.extraAnalytics.weddingDetailsLocation,
              }),
            },
          });
        case type === WeddingActionTypes.UPDATE_WEDDING_FIELD_ERROR:
          return of({
            type: isPartnerEdit()
              ? 'FAILED_TO_EDIT_WEDDING_PARTNER_NAMES_ANALYTICS'
              : 'FAILED_TO_EDIT_WEDDING_DETAILS_ANALYTICS',
            payload: { ...payload, name: getWeddingProfileName() },
          });
        case type === 'CHANGE_WEDDING_TYPE_SUCCESS':
        case type === 'CHANGE_WEDDING_TYPE_AND_SKIP':
          return of({
            type: 'EDITED_WEDDING_USER_TYPE_ANALYTICS',
            payload: { nextSlideName: payload },
          });

        default:
          return of();
      }
    }),
  );

interface IWeddingDetailsPropertiesGeneric {
  weddingPartnerName1: string;
  weddingPartnerName2: string;
  weddingDate: string;
  weddingTimeStampDate?: number | null;
  weddingDateString: string;
  weddingDateObject: UIDate;
  weddingLocation: IProfile['location'];
  weddingLocationShort: string;
  weddingVenue: IWeddingVenue;
  engagementDate: string;
  engagementDateString: string;
  engagementDateObject: UIDate;
  budgetInitialTarget: number;
  guestsInitialTarget: number;
  weddingThumbnailUrl?: string;
  venueBooked: boolean;
  budgetEstimateTotal: number;
  budgetActualTotal: number;
  budgetOverBudget: number;
  roles: IProfile['roles'];
}

export const weddingDetailsPropertiesGeneric = (
  state: IApplicationState,
): IWeddingDetailsPropertiesGeneric => {
  const {
    date,
    location,
    dateEngagement,
    budget,
    costs,
    guests: { estimate },
    partners: [weddingPartnerName1, weddingPartnerName2],
    photoCover,
    roles,
    l10n,
  } = state.weddings.profile;

  const market = gazetteer.getMarketByCountry(l10n.country);
  const venueBooked = getVenueBooked(state);
  const weddingVenue = getWeddingVenue(state);
  const isUK = market.country === CountryCodes.GB;

  const weddingDateDatePicker = mapDateToUI(date);
  const engagementDateDatePicker = mapDateToUI(dateEngagement);

  const weddingDateString = getDatepickerTitle(weddingDateDatePicker, false, false, isUK) || '';
  const engagementDateString =
    getDatepickerTitle(engagementDateDatePicker, false, false, isUK) || '';
  const weddingLocation = location || { name: '' };
  const weddingThumbnailUrl = photoCover?.path;
  const getWeddingTimeStampDate = () => {
    if (!date || isEmpty(date) || Object.values(date).every((date) => typeof date !== 'number'))
      return null;
    const { d, m, y } = date;
    const isYearANumber = y !== null;
    const isMonthANumber = m !== null && typeof m === 'number';
    const isDayANumber = d !== null && typeof d === 'number';
    //We are checking if values are only number because in any different type we won't be able to create proper timestamp
    if (isYearANumber && isMonthANumber && isDayANumber) {
      return new Date(y, m, d).getTime();
    }
  };

  return {
    weddingPartnerName1,
    weddingPartnerName2,
    weddingDate: mapToExactDate(weddingDateDatePicker, market) || '',
    weddingTimeStampDate: getWeddingTimeStampDate(),
    weddingDateObject: weddingDateDatePicker || ({} as UIDate),
    weddingDateString,
    engagementDate: mapToExactDate(engagementDateDatePicker, market) || '',
    engagementDateObject: engagementDateDatePicker || ({} as UIDate),
    engagementDateString,
    weddingLocation,
    weddingLocationShort: weddingLocation.name || '',
    weddingVenue: weddingVenue ? stripWeddingVenue(weddingVenue) : ({} as IWeddingVenue),
    budgetInitialTarget: Number(budget) || 0,
    guestsInitialTarget: (estimate && Number(estimate)) || 0,
    weddingThumbnailUrl,
    budgetEstimateTotal: Number(costs?.estimate) || 0,
    budgetActualTotal: Number(costs?.actual) || 0,
    budgetOverBudget: Number(costs?.estimate) - Number(budget) || 0,
    venueBooked, // bookings.venue is used now
    roles,
  };
};

const profileAnalytics = (
  { type, payload }: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: IDeps['getState'],
): void => {
  const filterLabelProps = (props: Record<string, any>) => {
    const newProps = { ...props };

    delete newProps.category;
    delete newProps.enteredValue;
    delete newProps.weddingUserType;
    delete newProps.weddingDetailsLocation;
    delete newProps.weddingAccessControl;
    delete newProps.userAccessControl;

    return newProps;
  };

  interface IWeddingPropertiesGeneric {
    weddingId: string;
    partners: string[];
  }

  const weddingPropertiesGeneric = (): IWeddingPropertiesGeneric => {
    const { partners, id: weddingId } = getState().weddings.profile;

    return {
      weddingId,
      partners,
    };
  };

  interface IUserDetailsPropertiesGeneric {
    firstName: string;
    lastName: string;
    userThumbnailUrl: string;
    userEmail?: string;
    userPhone?: string;
    locale?: string;
  }

  const userDetailsPropertiesGeneric = (): IUserDetailsPropertiesGeneric => {
    const contacts = getState().users.user?.contacts;
    const l10n = getState().users.user?.l10n;
    const name = getState().users.user?.name;
    const photo = getState().users.user?.photo;
    const { profile } = getState().weddings;

    const userThumbnailUrl = pathOr('', ['path'], photo);
    const firstName = pathOr('', ['partners', 0], profile);
    const lastName = pathOr('', [1], name);
    const userPhone = pathOr('', ['phone'], contacts);
    const userEmail = pathOr('', ['email'], contacts);
    const locale = pathOr('', ['locale'], l10n);

    let props: IUserDetailsPropertiesGeneric = {
      firstName,
      lastName,
      userThumbnailUrl,
      userEmail,
      userPhone,
      locale,
    };

    // override with payload
    const propName = path<keyof IUserDetailsPropertiesGeneric>(['name'], payload);
    const propValue = path<IUserDetailsPropertiesGeneric[keyof IUserDetailsPropertiesGeneric]>(
      [`${propName}`],
      payload,
    );

    if (propName && typeof propValue !== 'undefined' && typeof props[propName] !== 'undefined') {
      props[propName] = propValue;

      const omitKey =
        propName === 'userEmail' ? 'userPhone' : propName === 'userPhone' ? 'userEmail' : null;
      if (omitKey) {
        props = omit([omitKey])(props);
      }
    }

    return props;
  };

  const { track, identify } = bridebookAnalytics.getMethods('Wedding details', filterLabelProps);
  const state = getState();
  const getDetailsLocation = (): string => {
    const {
      venueConfirmation: { showVenueConfirm },
      quiz: {
        modal: { quizId, open: quizIsOpen },
      },
    } = getState();
    const { pathname } = getState().app;
    const location = pathname.replace('/', '').replace(/\?.*/, '');
    const isHome = pathname.includes('home');
    const isPlanning = pathname.includes('planning');
    switch (true) {
      case showVenueConfirm:
        return 'bookingConfirmationPopup';
      case !!(quizIsOpen && quizId):
        return `${quizId}Quiz`;
      case /wedding-/.test(pathname):
        return 'enquiry';
      case isHome:
        // if its home its done by countdown widget
        return 'countdownWidget';
      case isPlanning:
        // as with the case above, done by countdown widget
        return 'countdownWidgetplanning';
      default:
        return location;
    }
  };

  // DISABLED TILL FURTHER NOTICE ON USER TYPE SLIDE
  // const getWeddingUserType = (): string => {
  //   const { nextSlideName } = payload;
  //   const {
  //     allSlidesCollection: { init },
  //   } = getState().onboarding;
  //   return init[nextSlideName].pathName;
  // };

  switch (type) {
    case UserActionTypes.USER_EDITED_USER_DETAILS_ANALYTICS: {
      const { name } = payload;
      const userId = state.users.user?.id;
      track({
        event: 'User edited user details',
        category: 'User details',
        ...userDetailsPropertiesGeneric(),
        ...weddingPropertiesGeneric(),
        userDetailsField: name,
        userDetailsLocation: getDetailsLocation(),
        ...('userDetailsLocation' in payload && {
          userDetailsLocation: payload.userDetailsLocation,
        }),
      });
      if (userId && name === 'locale') {
        identify(userId, { locale: payload.locale });
      }
      break;
    }
    case UserActionTypes.USER_FAILED_TO_EDIT_USER_DETAILS_ANALYTICS: {
      const { name, value, error, location } = payload;
      track({
        event: 'User failed to edit user details',
        category: 'User details',
        ...weddingPropertiesGeneric(),
        userDetailsField: name,
        userDetailsLocation: location ?? getDetailsLocation(),
        enteredValue: value,
        reasonUserFailedToEditUserDetails: error,
      });
      break;
    }
    // DISABLED TILL FURTHER NOTICE ON USER TYPE SLIDE
    // case 'EDITED_WEDDING_USER_TYPE_ANALYTICS': {
    //   const weddingUserType = getWeddingUserType();
    //   identify(
    //     { weddingUserType },
    //     {
    //       event: 'Edited wedding user type',
    //       ...weddingPropertiesGeneric(),
    //       weddingUserType,
    //       weddingDetailsLocation: getDetailsLocation(),
    //       // TODO: will be required after linked accounts will be done
    //       weddingAccessControl: undefined,
    //       userAccessControl: undefined,
    //     },
    //   );
    //   break;
    // }
    // TODO: not really possible to fail for now - just DOM update
    // case 'FAILED_TO_EDIT_WEDDING_USER_TYPE_ANALYTICS':
    //   track({
    //     event: 'Failed to edit wedding user type',
    //     ...weddingPropertiesGeneric(),
    //     weddingDetailsLocation: '',
    //     enteredValue: '',
    //     reasonUserFailedToEditWeddingUserStatus: '',
    //   });
    //   break;
    case 'EDITED_WEDDING_PARTNER_NAMES_ANALYTICS':
      track({
        event: 'Edited wedding partner names',
        ...weddingPropertiesGeneric(),
        weddingDetailsField: payload.name,
        weddingDetailsLocation: getDetailsLocation(),
        ...('partnerEmail' in payload && { partnerEmail: payload.partnerEmail }),
        ...('weddingDetailsLocation' in payload && {
          weddingDetailsLocation: payload.weddingDetailsLocation,
        }),
      });
      break;
    case 'FAILED_TO_EDIT_WEDDING_PARTNER_NAMES_ANALYTICS': {
      const { name, value, error } = payload;
      track({
        event: 'Failed to edit wedding partner names',
        ...weddingPropertiesGeneric(),
        weddingDetailsField: name,
        weddingDetailsLocation: getDetailsLocation(),
        enteredValue: value,
        reasonFailedToEditWeddingPartnerNames: error,
      });
      break;
    }
    case 'EDITED_WEDDING_DETAILS_ANALYTICS':
      track({
        event: CriticalWebEvents.EDITED_WEDDING_DETAILS,
        ...weddingPropertiesGeneric(),
        ...weddingDetailsPropertiesGeneric(getState()),
        weddingDetailsField: payload.name,
        weddingDetailsLocation: getDetailsLocation(),
        ...('partnerEmail' in payload && { partnerEmail: payload.partnerEmail }),
        ...('weddingDetailsLocation' in payload && {
          weddingDetailsLocation: payload.weddingDetailsLocation,
        }),
      });

      /**
       * We need to track changes to wedding date for all wedding users.
       */
      if (payload.name === 'date') {
        const state = getState();

        /**
         * We can filter out the current user from the list of wedding users.
         */
        const users = state.weddings.profile.users;

        for (const user of users) {
          serverSideTrack({
            state,
            event: CriticalWebEvents.EDITED_WEDDING_DETAILS,
            category: 'Wedding details',
            identifyProps: weddingDetailsPropertiesGeneric(state),
            specificEventProps: {
              ...weddingPropertiesGeneric(),
              ...weddingDetailsPropertiesGeneric(state),
              weddingDetailsField: payload.name,
              weddingDetailsLocation: getDetailsLocation(),
              userId: user,
              ...('weddingDetailsLocation' in payload && {
                weddingDetailsLocation: payload.weddingDetailsLocation,
              }),
            },
          });
        }
      }
      break;
    case 'FAILED_TO_EDIT_WEDDING_DETAILS_ANALYTICS': {
      const { name, value, error } = payload;
      track({
        event: 'Failed to edit wedding details',
        ...weddingPropertiesGeneric(),
        weddingDetailsField: name,
        weddingDetailsLocation: getDetailsLocation(),
        enteredValue: value,
        reasonFailedToEditWeddingPartnerNames: error,
      });
      break;
    }

    case WeddingActionTypes.SELECT_PREMIUM_PRICING_OPTION: {
      const { plan, category } = payload;

      track({
        event: 'Completed user pricing popup',
        category,
        userPricingConfirmationOption: plan,
      });
      break;
    }

    case WeddingActionTypes.UPDATED_WEDDING_LOCATION_ANALYTICS: {
      track({
        event: 'Changed wedding location',
        category: 'Wedding details',
        weddingDetailsLocation: 'signUpPopup',
        ...weddingLocationPropertiesGeneric(getState, payload),
      });
      break;
    }

    case WeddingActionTypes.WEDDING_PROFILE_LOADED_ANALYTICS: {
      const userId = state.users.user?.id;
      const market = selectMarketWithFallback(state);
      const countryCode = market.country;
      const countryName = countryCode ? Gazetteer.getCountryName(countryCode) : undefined;

      if (userId && market) {
        identify(userId, {
          countryName,
        });
      }
      break;
    }

    default:
      break;
  }
};

export default profileAnalytics;
