import type { INotificationObject } from '@bridebook/toolbox/src/types';
import { mapProviderToString } from 'lib/auth/utils/auth-provider-utils';
import type { INotification } from '../types';
import mapFBErrorToNotification from './linked-accounts-map-fb-error-to-notification';

/**
 * Return appropriate notification object to be used in NotificationBlock
 * @method resolveNotification
 * @param {string} type - type of notification
 * @param {Object} notification object, can be firebase error or custom object
 * @returns {Object} notification
 */
const resolveNotification = (type: string, notificationObj: INotificationObject) => {
  const notification = {} as INotification;
  const defaultEmptyMessage = '';

  switch (type) {
    case 'LINK_ACCOUNTS_ERROR': {
      notification.type = 'alert';
      const provider = notificationObj.credential
        ? notificationObj.credential.provider
        : notificationObj.provider;

      if (provider) {
        notification.message = mapFBErrorToNotification(notificationObj, provider);
      } else {
        notification.message = notificationObj.message || defaultEmptyMessage;
      }
      break;
    }
    case 'LINK_ACCOUNTS_SUCCESS':
      notification.type = 'success';
      notification.message = `You can now use your ${mapProviderToString(
        notificationObj.provider,
      )} account to login to Bridebook`;
      break;
    case 'ERROR':
      notification.type = 'alert';
      notification.message = notificationObj.message || defaultEmptyMessage;
      break;
    case 'SUCCESS':
      notification.type = 'success';
      notification.message = notificationObj.message || defaultEmptyMessage;
      break;
    default:
      break;
  }
  return notification;
};

export default resolveNotification;
