import { pathOr } from 'ramda';
import { ChangeEvent } from 'react';
import { TranslatedError, startZendeskHelpCenter } from '@bridebook/toolbox/src';
import { Market } from '@bridebook/toolbox/src/gazetteer';
import type { INotificationObject } from '@bridebook/toolbox/src/types';
import { toggleSnackbar } from 'lib/bbcommon/actions';
import { getI18n } from 'lib/i18n/getI18n';
import { IFeedbackFormField } from 'lib/settings/feedback-form';
import { IDeps } from 'lib/types';
import { SettingsActionTypes } from './action-types';
import {
  createMicrositeErrorAnalytics,
  createMicrositeSuccessAnalytics,
  triggerFeedbackFormAnalytics,
  triggerZendeskWidgetAnalytics,
} from './analytics/actions';
import resolveNotification from './utils/linked-accounts-resolve-notification';

export const setSettingsNotification = (type: string, notificationObj: INotificationObject) => {
  const notification = resolveNotification(type, notificationObj);
  return toggleSnackbar(notification.type, notification.message);
};

export const changeCorrespondenceEmailForm = (e: ChangeEvent<HTMLInputElement>) => {
  const {
    target: { value },
  } = e;
  return {
    type: SettingsActionTypes.SET_CORRESPONDENCE_EMAIL_FORM_FIELD,
    payload: value,
  };
};

export const createMicrositeSuccess =
  ({ id }: { id: string }) =>
  ({ dispatch }: IDeps) => {
    const i18n = getI18n();
    dispatch(toggleSnackbar('success', i18n.t('settings:microsite.confirmationSnackbar')));
    dispatch(createMicrositeSuccessAnalytics({ id }));
    return {
      type: SettingsActionTypes.CREATE_MICROSITE_SUCCESS,
      payload: id,
    };
  };

export const createMicrositeError =
  ({
    id,
    error,
    showSnackbar = false,
  }: {
    id: string;
    error: Error | TranslatedError;
    showSnackbar: boolean;
  }) =>
  ({ dispatch }: IDeps) => {
    if (showSnackbar) {
      const message = 'messageI18n' in error ? error.messageI18n : error.message;
      dispatch(toggleSnackbar('alert', message || error.message));
    }
    dispatch(createMicrositeErrorAnalytics({ id, error }));
    return {
      type: SettingsActionTypes.CREATE_MICROSITE_ERROR,
      payload: { error },
    };
  };

export const showZendeskHelpCenter =
  () =>
  ({ getState, dispatch }: IDeps) => {
    const userEmail = pathOr('', ['contacts', 'email'], getState().users.user);
    const userName = getState().weddings.profile.partners[0];

    startZendeskHelpCenter({ userEmail, userName, contactFormView: false });
    dispatch(triggerZendeskWidgetAnalytics());

    return {
      type: SettingsActionTypes.SHOW_ZENDESK_HELP_CENTER,
    };
  };

export const showZendeskContactForm =
  () =>
  ({ getState, dispatch }: IDeps) => {
    const userEmail = pathOr('', ['contacts', 'email'], getState().users.user);
    const userName = getState().weddings.profile.partners[0];

    startZendeskHelpCenter({ userEmail, userName, contactFormView: true });
    dispatch(triggerZendeskWidgetAnalytics());

    return {
      type: SettingsActionTypes.SHOW_ZENDESK_CONTACT_FORM,
    };
  };

export const toggleFeedbackPopup =
  ({ show }: { show: boolean }) =>
  ({ dispatch }: IDeps) => {
    if (show) {
      dispatch(triggerFeedbackFormAnalytics());
    }

    return {
      type: SettingsActionTypes.TOGGLE_FEEDBACK_POPUP,
      payload: show,
    };
  };

export const sendFeedback =
  () =>
  ({ dispatch }: IDeps) => {
    const i18n = getI18n();
    dispatch(toggleFeedbackPopup({ show: false }));
    dispatch(toggleSnackbar('success', i18n.t('settings:feedback.confirmationSnackbar')));
    return {
      type: SettingsActionTypes.SEND_FEEDBACK,
    };
  };

interface IChangeFeedbackFormFieldEvent {
  name: keyof IFeedbackFormField;
  value: IFeedbackFormField[keyof IFeedbackFormField];
}

export const changeFeedbackFields = ({ name, value }: IChangeFeedbackFormFieldEvent) => ({
  type: SettingsActionTypes.CHANGE_FEEDBACK_FIELDS,
  payload: { name, value },
});

export const deleteMicrosite = (id: string) => ({
  type: SettingsActionTypes.DELETE_MICROSITE,
  payload: { id },
});

export const changeWeddingCountry = (market: Market) => ({
  type: SettingsActionTypes.CHANGE_WEDDING_COUNTRY,
  payload: { market },
});

export const changeWeddingCountryCompleted = () => ({
  type: SettingsActionTypes.CHANGE_WEDDING_COUNTRY_COMPLETED,
});

export const changeWeddingCountryFailed = () => ({
  type: SettingsActionTypes.CHANGE_WEDDING_COUNTRY_FAILED,
});

export const showLocationChangeModal = () => ({
  type: SettingsActionTypes.SHOW_LOCATION_CHANGE_MODAL,
});

export const closeLocationChangeModal = () => ({
  type: SettingsActionTypes.CLOSE_LOCATION_CHANGE_MODAL,
});
