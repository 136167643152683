import { format, setDate, setMonth } from 'date-fns';
import type { IDatePickerUI } from '@bridebook/toolbox/src/datepicker/types';
import getLocalisedSeason from '../getLocalisedSeason';

/**
 * Function `getDatepickerTitle` returns friendly date string from datepicker object
 *
 * @function getDatepickerTitle
 * @param {Object} - date picker Object
 * @param {Boolean?} - use don't remember text or empty when date is undecided/not filled
 * @param {boolean} - shortMonth, whether to use short month MMM in title
 * @param {boolean} - isUK whether user's countryCode is set to GB
 * @returns {String} - returns friendly date string
 */
const getDatepickerTitle = (
  datePickerUI: IDatePickerUI | null,
  dontRemember?: boolean,
  shortMonth: boolean = false,
  isUK: boolean = true,
): string => {
  if (!datePickerUI) return '';

  const { year, yearUndecided, month, monthUndecided, season, day, weekDay, dayUndecided } =
    datePickerUI;

  const monthString = month
    ? // @ts-ignore
      `${format(setMonth(new Date(), month), shortMonth ? 'MMM' : 'MMMM')} `
    : '';

  const localisedSeason = season ? `${getLocalisedSeason(season, isUK)} ` : '';

  const hasMonth = month || season;
  const hasDay = day || weekDay;

  if (
    dontRemember &&
    !(yearUndecided && monthUndecided && dayUndecided) &&
    (yearUndecided || monthUndecided || dayUndecided) &&
    !hasMonth &&
    !hasDay &&
    !year
  ) {
    return '';
  }

  if (yearUndecided && !hasMonth && !hasDay) {
    return dontRemember ? "I don't remember" : 'Undecided';
  }

  if (yearUndecided && monthUndecided && weekDay) {
    return `A ${weekDay}`;
  }

  if (year && !month && !season && !day && !weekDay) {
    return `${year}`;
  }

  if ((year || yearUndecided) && weekDay) {
    return `A ${weekDay} in ${monthString || localisedSeason}${year}`;
  }

  if ((year || yearUndecided) && (month || season) && !weekDay && !day) {
    return `${monthString || localisedSeason}${year}`;
  }

  if (!year && month && day) {
    // @ts-ignore
    const fullDate = setDate(setMonth(new Date(), month), day);
    return `${format(fullDate, 'do')} of ${format(fullDate, 'MMMM')}`;
  }

  if (year && month && day) {
    // @ts-ignore
    const fullDate = setDate(new Date(year, month, 1), day);
    return format(fullDate, 'do MMM yyyy');
  }

  return '';
};

export default getDatepickerTitle;
