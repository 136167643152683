import { IUISupplier } from '@bridebook/toolbox/src/types';
import { IWeddingVenue } from '../types';

const stripWeddingVenue = ({ town, name, id, publicId }: IUISupplier): IWeddingVenue => ({
  name: name || '',
  collection: 'suppliers',
  id,
  town: town!,
  // Custom suppliers don't have a public id
  ...(publicId && { publicID: publicId }),
});

export default stripWeddingVenue;
