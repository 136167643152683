import {
  changeCorrespondenceEmailStart,
  onUserListener,
  saveUserProvider,
  setPartnerNames,
} from 'lib/users/actions';

export enum UserActionTypes {
  UPDATE_USER_FIELD = '[User] Update field on User',
  ON_USER_LISTENER = '[User] On user listener',
  SET_PARTNER_NAMES_FORM_FIELD = '[User] Set partner names form field',
  SAVE_VENUE_LOCATION = '[User] Save venue location',
  SAVE_USER_PROVIDER = '[User] Save user auth provider',
  USER_EDITED_USER_DETAILS_ANALYTICS = '[Analytics] [User] Edit user details success analytics',
  USER_FAILED_TO_EDIT_USER_DETAILS_ANALYTICS = '[Analytics] [User] Edit user details error analytics',
  CHANGE_CORRESPONDENCE_EMAIL_START = '[User] Changed correspondence email start',
  CHANGE_CORRESPONDENCE_EMAIL_SUCCESS = '[User] Changed correspondence email success',
  CHANGE_CORRESPONDENCE_EMAIL_ERROR = '[User] Changed correspondence email error',
  MARK_GUESTLIST_AS_VIEWED = '[User] Mark guestlist page as viewed',
  UPDATE_USER_DETAILS_ERROR = '[User] Update user details error',
  MARK_USER_AS_DELETED_START = '[User] Mark user as deleted',
  MARK_USER_AS_DELETED_SUCCESS = '[User] Mark user as deleted success',
  MARK_USER_AS_DELETED_ERROR = '[User] Mark user as deleted error',
  UPDATE_PROFILE_PHOTO_UPLOAD_PROGRESS = '[Users] Update profile photo upload progress',
}

export type IChangeCorrespondenceEmailAction = ReturnType<typeof changeCorrespondenceEmailStart>;

export type IOnUserListenerAction = ReturnType<typeof onUserListener>;

export type ISetPartnerNamesFormAction = ReturnType<typeof setPartnerNames>;

export type ISaveUserProviderAction = ReturnType<typeof saveUserProvider>;
