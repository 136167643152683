import { changeCorrespondenceEmailStart } from 'lib/users/actions';
import {
  changeCorrespondenceEmailForm,
  changeFeedbackFields,
  changeWeddingCountry,
  toggleFeedbackPopup,
} from './actions';

export enum SettingsActionTypes {
  DELETE_MICROSITE = '[Settings] Delete microsite',
  CREATE_MICROSITE_SUCCESS = '[Settings] Create microsite success',
  CREATE_MICROSITE_ERROR = '[Settings] Create microsite error',
  LINK_ACCOUNTS_START = '[Settings] Link accounts start',
  SET_CORRESPONDENCE_EMAIL_FORM_FIELD = '[Settings] Set correspondence email form field',
  TOGGLE_FEEDBACK_POPUP = '[Settings] Toggle feedback popup',
  SEND_FEEDBACK = '[Settings] Send feedback',
  CHANGE_FEEDBACK_FIELDS = '[Settings] Change feedback fields',
  SHOW_ZENDESK_CONTACT_FORM = '[Settings] Show zendesk contact form',
  SHOW_ZENDESK_HELP_CENTER = '[Settings] Show zendesk help center',
  CHANGE_WEDDING_COUNTRY = '[Settings] Change wedding country',
  CHANGE_WEDDING_COUNTRY_COMPLETED = '[Settings] Change wedding country completed',
  CHANGE_WEDDING_COUNTRY_FAILED = '[Settings] Change wedding country failed',
  SHOW_LOCATION_CHANGE_MODAL = '[Settings] Show location change modal',
  CLOSE_LOCATION_CHANGE_MODAL = '[Settings] Close location change modal',
}

export type ChangeCorrespondenceEmailFormAction = ReturnType<typeof changeCorrespondenceEmailForm>;

export type ChangeCorrespondenceEmailStartAction = ReturnType<
  typeof changeCorrespondenceEmailStart
>;

export type ToggleFeedbackPopupAction = ReturnType<ReturnType<typeof toggleFeedbackPopup>>;

export type ChangeFeedbackFieldsAction = ReturnType<typeof changeFeedbackFields>;

export type ChangeWeddingCountryAction = ReturnType<typeof changeWeddingCountry>;
