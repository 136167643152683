import { Observable, of } from 'rxjs';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AuthActionTypes } from 'lib/auth/action-types';
import { scrollPositionKeep } from 'lib/bbcommon/utils/scroll-position-keep';
import { Action, IApplicationState, IDeps, IEpicDeps } from 'lib/types';
import { UIActionTypes } from 'lib/ui/action-types';
import { BBCommonActionTypes } from './action-types';
import { ISnackbarState } from './types';

export const toggleOverlayPortal =
  (show: boolean) =>
  ({ getState }: IDeps) => {
    const { scrollPosition } = getState().bbcommon;
    const setScrollPosition = show && process.browser ? window.scrollY : scrollPosition;

    return {
      type: BBCommonActionTypes.TOGGLE_OVERLAY_PORTAL,
      payload: {
        opened: show,
        scrollPosition: setScrollPosition,
      },
    };
  };

export const hideSnackbar =
  () =>
  ({ dispatch }: IDeps) => {
    setTimeout(() => {
      dispatch({ type: BBCommonActionTypes.RESET_SNACKBAR_CONFIG });
    }, 300);
    return { type: BBCommonActionTypes.HIDE_SNACKBAR };
  };

export const toggleSnackbar = (
  mode: ISnackbarState['mode'],
  text: string,
  linesLimit?: number,
  extra?: {
    timeoutMs?: number;
    action?: ISnackbarState['action'];
    actionData?: ISnackbarState['actionData'];
    analyticsData?: ISnackbarState['analyticsData'];
    emoji?: string;
    customOffset?: number;
  },
) => ({
  type: BBCommonActionTypes.TOGGLE_SNACKBAR,
  payload: { mode, text, linesLimit, extra },
});

export const onModalToggle = ({ show, id }: { show: boolean; id: string }) => ({
  type: BBCommonActionTypes.ON_MODAL_TOGGLE,
  payload: { show, id },
});

export const bbCommonEpic = (action$: Observable<Action>, { state$ }: IEpicDeps): Observable<any> =>
  action$.pipe(
    filter(
      (action) =>
        action.type === 'ROUTE_CHANGE_COMPLETE' ||
        action.type === BBCommonActionTypes.ON_MODAL_TOGGLE ||
        action.type === AuthActionTypes.TOGGLE_AUTH_FORM_STATE_TO_SIGNUP,
    ),
    withLatestFrom(state$),
    mergeMap(([action, state]: [Action, IApplicationState]) => {
      const { openedModalIds } = state.bbcommon;

      if (
        action.type === 'ROUTE_CHANGE_COMPLETE' ||
        action.type === AuthActionTypes.TOGGLE_AUTH_FORM_STATE_TO_SIGNUP
      ) {
        if (action.type === 'ROUTE_CHANGE_COMPLETE' && openedModalIds.length > 0) {
          scrollPositionKeep({
            show: true,
            scrollPosition: 0,
          });
        }

        return of({
          type: UIActionTypes.TOGGLE_NAV_MENU_DRAWER,
          payload: false,
        });
      }

      if (action.type === BBCommonActionTypes.ON_MODAL_TOGGLE) {
        const { openedOverlayPortal } = state.bbcommon;
        if (!openedOverlayPortal && action.payload.show && openedModalIds.length > 0) {
          return of(toggleOverlayPortal(true));
        }

        if (openedOverlayPortal && !action.payload.show) {
          return of(toggleOverlayPortal(false));
        }
      }

      return of();
    }),
  );
