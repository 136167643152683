import {
  fetchCountryBoundsStart,
  fetchCountryBoundsSuccess,
  updateInboxUnread,
  updatePartnerNames,
  updateWedding,
  updateWeddingField,
  updateWeddingPreferences,
  weddingProfileSaveDate,
} from 'lib/weddings/actions';

export enum WeddingActionTypes {
  UPDATE_WEDDING = '[Wedding] Update wedding',
  UPDATE_WEDDING_FIELD = '[Wedding] Update wedding field',
  UPDATE_WEDDING_FIELD_SUCCESS = '[Wedding] Update wedding field success',
  UPDATE_WEDDING_FIELD_ERROR = '[Wedding] Update wedding field error',
  UPDATE_CURRENCY = '[Wedding] Update currency',
  SELECT_PREMIUM_PRICING_OPTION = '[Wedding] Select premium pricing option',
  SAVE_WEDDING_DATE = '[Wedding] Save wedding date',
  SAVE_WEDDING_DATE_SUCCESS = '[Wedding] Save wedding date success',
  SAVE_WEDDING_DATE_ERROR = '[Wedding] Save wedding date error',
  UPDATED_WEDDING_LOCATION_ANALYTICS = '[Analytics] [Wedding] Updated wedding location analytics',
  FETCH_PROFILE_COUNTRY_BOUNDS_START = '[Wedding] Fetch Google maps LatLngBounds from country code start',
  FETCH_PROFILE_COUNTRY_BOUNDS_SUCCESS = '[Wedding] Fetch Google maps LatLngBounds from country code success',
  FETCH_PROFILE_COUNTRY_BOUNDS_ERROR = '[Wedding] Fetch Google maps LatLngBounds from country code error',
  GLOBAL_LISTENERS_INITIALISED = '[Wedding] Global listeners initialised',
  UPDATE_INBOX_UNREAD = '[Wedding] Update inbox unread',
  UPDATE_WEDDING_PREFERENCES = '[Wedding] Update wedding preferences',
  UPDATE_PARTNER_NAMES = '[Wedding] Update partner names',
  WEDDING_PROFILE_LOADED_ANALYTICS = '[Analytics] [Wedding] Wedding profile loaded',
}

export type IUpdateWeddingAction = ReturnType<ReturnType<typeof updateWedding>>;
export type IUpdateWeddingFieldAction = ReturnType<typeof updateWeddingField>;
export type IWeddingProfileSaveDateAction = ReturnType<typeof weddingProfileSaveDate>;
export type IFetchCountryBoundsStartAction = ReturnType<typeof fetchCountryBoundsStart>;
export type IFetchCountryBoundsSuccessAction = ReturnType<typeof fetchCountryBoundsSuccess>;
export type IUpdateInboxUnreadAction = ReturnType<typeof updateInboxUnread>;
export type IUpdateWeddingPreferencesAction = ReturnType<typeof updateWeddingPreferences>;
export type IUpdatePartnerNamesAction = ReturnType<typeof updatePartnerNames>;
