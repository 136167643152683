import {
  addCollaboratorInvite,
  changeAuthEmail,
  changeAuthEmailError,
  changeAuthEmailForm,
  changePasswordError,
  changePasswordSuccess,
  emailExistsWithDifferentProvider,
  initChangePassword,
  linkEmailAccount,
  linkSocialAccount,
  onAuthFormFieldChange,
  resetPassword,
  resetPasswordError,
  signInError,
  signInOrSignUpError,
  signOutCompleted,
  signUpError,
  toggleAuthFormStateToSignup,
  toggleResetPasswordModal,
  toggleSelectedCountryError,
  userSetupCompleted,
} from 'lib/auth/actions';
import {
  completedEnquiryFormOnLoggedOutPages,
  triggeredEnquiryFormOnLoggedOutPages,
} from 'lib/auth/actions/request-brochure';
import { setProfilePhotoUploadProgress } from '../users/actions/set-profile-photo-upload-progress';

export enum AuthActionTypes {
  TOGGLE_AUTH_FORM_STATE_TO_SIGNUP = '[Auth] Toggle signup form state',
  TOGGLE_PASSWORD_RESET = '[Auth] Toggle password reset form',
  SIGNUP_EMAIL_EXISTS = '[Auth] Toggle "Email Exists" form state',
  SIGNUP_EMAIL_EXISTS_WITH_DIFFERENT_PROVIDER = '[Auth] Toggle "Email Exists with different provider" form state',
  TOGGLE_AUTH_MODAL_ANALYTICS = '[Analytics] [Auth] Toggle auth popup analytics',
  LINK_EMAIL_ACCOUNT = '[Auth] Link email account',
  LINK_SOCIAL_ACCOUNT = '[Auth] Link social account',
  LINK_ACCOUNTS_SUCCESS = '[Auth] Link account success',
  RESET_PASSWORD = '[Auth] Request to reset a password from Firebase',
  RESET_PASSWORD_SUCCESS = '[Auth] Request to reset a password from Firebase success',
  RESET_PASSWORD_ERROR = '[Auth] Request to reset a password from Firebase error',
  CHANGE_PASSWORD_SUCCESS = '[Auth] Change password success',
  CHANGE_PASSWORD_ERROR = '[Auth] Change password error',
  CHANGE_AUTH_EMAIL = '[Auth] Change auth email',
  CHANGE_AUTH_EMAIL_SUCCESS = '[Auth] Change auth email success',
  CHANGE_AUTH_EMAIL_ERROR = '[Auth] Change auth email error',
  INIT_CHANGE_PASSWORD = '[Auth] Change pass init',
  SET_AUTH_EMAIL_FORM_FIELD = '[Auth] Set auth email form field',
  DISABLE_AUTH_FORM = '[Auth] Disable auth form',
  SIGN_IN_WITH_PASSWORD = '[Auth] Sign in with password provider',
  SIGN_IN_WITH_PASSWORD_SUCCESS = '[Auth] Sign in with password provider success',
  SIGN_IN_WITH_SOCIAL_PROVIDER = '[Auth] Sign in with social provider',
  SIGN_IN_WITH_SOCIAL_SUCCESS = '[Auth] Sign in with social provider success',
  SIGN_UP = '[Auth] Sign up',
  SIGN_UP_SUCCESS = '[Auth] Sign up success',
  SIGN_UP_ERROR = '[Auth] Sign up error',
  TRIGGER_SIGN_OUT = '[Auth] Trigger sign out',
  SIGN_OUT_COMPLETED = '[Auth] Sign out completed',
  USER_IS_SIGNED_OUT = '[Auth] User is signed out',
  REDIRECT_AFTER_AUTH_DONE = '[Auth] Redirect after authentication done',
  ON_FIREBASE_SIGN_OUT = '[Auth] Firebase sign out',
  ON_AUTH_FORM_FIELD_CHANGE = '[Auth] Set value on auth form field',
  SIGN_IN_ERROR = '[Auth] Sign in error',
  SIGN_IN_OR_SIGN_UP_ERROR = '[Auth] Sign in or sign up error',
  TOGGLE_SELECTED_COUNTRY_ERROR = '[Auth] Toggle selected country error',
  ADD_COLLABORATOR_INVITE = '[Auth] Add collaborator Invite',
  SAVE_NEXT_PATH_AFTER_ONBOARDING = '[Auth] Save next path after onboarding',
  VIEWED_SIGNUP_PAGE_ANALYTICS = '[Analytics] [Auth] Viewed signup page',
  USER_SETUP_COMPLETED = '[Auth] User setup completed',
  USER_SETUP_STARTED = '[Auth] User setup started',
  OPENED_ENQUIRY_FORM = '[Auth] Opened enquiry form',
  COMPLETED_ENQUIRY_FORM_STEP = '[Auth] Completed enquiry form step',
  TRIGGERED_ENQUIRY_FORM_ON_LOGGED_OUT_PAGE = '[Auth] Triggered enquiry form on logged out page',
  COMPLETED_ENQUIRY_FORM_ON_LOGGED_OUT_PAGE = '[Auth] Completed enquiry form on logged out page',
}

export type IEnableResetPasswordAction = ReturnType<typeof toggleResetPasswordModal>;

export type IEmailExistsWithDifferentProviderAction = ReturnType<
  typeof emailExistsWithDifferentProvider
>;

export type ILinkEmailAccountAction = ReturnType<typeof linkEmailAccount>;

export type ILinkSocialAccountAction = ReturnType<typeof linkSocialAccount>;

export type IAddCollaborativeInviteAction = ReturnType<typeof addCollaboratorInvite>;

export type IResetPasswordAction = ReturnType<typeof resetPassword>;

export type IResetPasswordErrorAction = ReturnType<ReturnType<typeof resetPasswordError>>;

export type IChangeAuthEmailAction = ReturnType<typeof changeAuthEmail>;

export type IChangeAuthEmailErrorAction = ReturnType<ReturnType<typeof changeAuthEmailError>>;

export type IChangeAuthEmailFormAction = ReturnType<typeof changeAuthEmailForm>;

export type ISignUpErrorAction = ReturnType<ReturnType<typeof signUpError>>;

export type ISignOutCompletedAction = ReturnType<typeof signOutCompleted>;

export type SignOutCompleted = ReturnType<typeof signOutCompleted>;

export type UserSetupCompletedAction = ReturnType<ReturnType<typeof userSetupCompleted>>;

export type IOnAuthFOrmFieldChangeAction = ReturnType<typeof onAuthFormFieldChange>;

export type ISignInErrorAction = ReturnType<typeof signInError>;

export type ISignInOrSignUpErrorAction = ReturnType<typeof signInOrSignUpError>;

export type IToggleAuthFormStateToSignupAction = ReturnType<
  ReturnType<typeof toggleAuthFormStateToSignup>
>;

export type IChangePasswordSuccessAction = ReturnType<ReturnType<typeof changePasswordSuccess>>;

export type IChangePasswordErrorAction = ReturnType<ReturnType<typeof changePasswordError>>;

export type IToggleSelectedCountryError = ReturnType<ReturnType<typeof toggleSelectedCountryError>>;

export type IInitChangePassAction = ReturnType<typeof initChangePassword>;

export type IUpdateProfilePhotoUploadProgress = ReturnType<typeof setProfilePhotoUploadProgress>;

export type ITriggeredEnquiryFormOnLoggedOutPages = ReturnType<
  typeof triggeredEnquiryFormOnLoggedOutPages
>;

export type ICompletedEnquiryFormOnLoggedOutPages = ReturnType<
  typeof completedEnquiryFormOnLoggedOutPages
>;
