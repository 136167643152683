const getLocalisedSeason = (season: string, isUK: boolean = true): string => {
  switch (season.toLowerCase()) {
    case 'summer':
      return isUK ? 'Summer' : 'Jul/Aug/Sep';
    case 'autumn':
      return isUK ? 'Autumn' : 'Oct/Nov/Dec';
    case 'winter':
      return isUK ? 'Winter' : 'Jan/Feb/Mar';
    case 'spring':
      return isUK ? 'Spring' : 'Apr/May/Jun';
    default:
      throw new Error('There is no such season!!');
  }
};

export default getLocalisedSeason;
