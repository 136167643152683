export type NavigationMethod = 'mobileHomeTab' | 'mobileHeaderFull' | 'desktopHeaderFull';

export type NavigationDestination =
  | 'articles'
  | 'booked'
  | 'budget'
  | 'catering'
  | 'checklist'
  | 'decoration'
  | 'dress'
  | 'entertainment'
  | 'guestlist'
  | 'hair & beauty'
  | 'home'
  | 'logout'
  | 'menswear'
  | 'messages'
  | 'photographers'
  | 'planning'
  | 'scrapbook'
  | 'search'
  | 'settings'
  | 'shortlist'
  | 'suppliers'
  | 'transport'
  | 'venues & suppliers'
  | 'venues'
  | 'videographers'
  | 'florist'
  | 'cakes'
  | 'quizzes';

export type TilesCarouselLocation = 'home';

export type TilesCarouselAction = 'left' | 'right';

export interface NavPropArg {
  method: NavigationMethod;
  destination: NavigationDestination;
}

export interface CarouselPropArg {
  action: TilesCarouselAction;
  location: TilesCarouselLocation;
}

export enum CriticalWebEvents {
  REGISTERED_AS_USER = 'Registered as User',
  EMAILED_SUPPLIER = 'Emailed Supplier',
  COLLABORATED_ON_WEDDING_PROFILE = 'Collaborated on wedding profile',
  INVITED_COLLABORATED_TO_WEDDING_PROFILE = 'Invited collaborator to wedding profile',
  BOOKED_SUPPLIER = 'Booked supplier',
  EDITED_WEDDING_DETAILS = 'Edited wedding details',
  LOGGED_IN_AS_USER = 'Logged In as User',
  USER_REGISTRATION_FAILED = 'User registration failed',
}
