import {
  changedAppIconAnalytics,
  clickedAnalyticsAction,
  clickedToUploadProfilePhotoAnalytics,
  createMicrositeErrorAnalytics,
  createMicrositeSuccessAnalytics,
  deleteMicrositeSuccessAnalytics,
  selectPredefinedProfilePhotoAnalytics,
  shareMicrositeAnalytics,
  triggerDeleteMicrositeAnalytics,
  uploadProfilePhotoAnalytics,
} from 'lib/settings/analytics/actions';

export enum SettingsAnalyticsActionTypes {
  TRIGGER_DELETE_MICROSITE_ANALYTICS = '[Analytics] Trigger delete microsite',
  DELETE_MICROSITE_SUCCESS_ANALYTICS = '[Analytics] Delete microsite success',
  CREATE_MICROSITE_SUCCESS_ANALYTICS = '[Analytics] Create microsite success',
  CREATE_MICROSITE_ERROR_ANALYTICS = '[Analytics] Create microsite error',
  SHARE_MICROSITE_ANALYTICS = '[Analytics] Share microsite',
  OPEN_APP_VERSION_POPUP_ANALYTICS = '[Analytics] Open app version popup',
  USER_UPLOAD_PROFILE_PHOTO_ANALYTICS = '[Analytics] User upload profile photo',
  USER_DELETE_PROFILE_PHOTO_ANALYTICS = '[Analytics] User delete profile photo',
  USER_CLICKED_DELETE_ACCOUNT_ANALYTICS = '[Analytics] Clicked delete account',
  USER_CHANGED_APP_ICON_ANALYTICS = '[Analytics] User changed app icon',
  USER_CLICKED_UPLOAD_PROFILE_PHOTO_ANALYTICS = '[Analytics] User clicked upload profile photo',
  USER_SELECTED_PREDEFINED_PROFILE_PHOTO = '[Analytics] User selected predefined profile photo',
  CLICKED_ANALYTICS_ACTION = '[Analytics] Clicked analytics action',
  TRIGGERED_INTERCEPT_POPUP = '[Analytics] Triggered intercept popup',
}

export type CreateMicrositeSuccessAnalyticsAction = ReturnType<
  typeof createMicrositeSuccessAnalytics
>;

export type CreateMicrositeErrorAnalyticsAction = ReturnType<typeof createMicrositeErrorAnalytics>;

export type TriggerDeleteMicrositeAnalyticsAction = ReturnType<
  typeof triggerDeleteMicrositeAnalytics
>;

export type DeleteMicrositeSuccessAnalyticsAction = ReturnType<
  typeof deleteMicrositeSuccessAnalytics
>;

export type ShareMicrositeAnalyticsAction = ReturnType<typeof shareMicrositeAnalytics>;

export type ChangedAppIconAnalyticsAction = ReturnType<typeof changedAppIconAnalytics>;

export type ClickedToUploadProfilePhotoAnalytics = ReturnType<
  typeof clickedToUploadProfilePhotoAnalytics
>;

export type UploadProfilePhotoAnalytics = ReturnType<typeof uploadProfilePhotoAnalytics>;

export type SelectPredefinedProfilePhotoAnalytics = ReturnType<
  typeof selectPredefinedProfilePhotoAnalytics
>;

export type ClickedAnalyticsAction = ReturnType<typeof clickedAnalyticsAction>;
