import {
  Auth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  fetchSignInMethodsForEmail,
} from 'firebase/auth';
import { AuthProviders } from 'lib/auth/types';

/**
 * There are few types of errors returned, one has provider data at top level,
 * another inside of credential object, and third one without provider data at all,
 * identify which one is it and save provider data as top level property
 * @method findProviderInError
 * @param {Object} error thrown from action
 * @param {Function} firebaseAuth fn from our dependency middleware
 * @returns {Object} error now with provider as a top level property
 */
// export const findProviderInError = async (
//   error: Error,
//   firebaseAuth: Function,
// ) => {
//   if (!error.provider && error.credential && error.credential.provider) {
//     const providers = await firebaseAuth().fetchSignInMethodsForEmail(
//       error.email,
//     );
//     if (providers.length && providers.indexOf(error.credential.provider) < 0) {
//       error.provider = providers[0];
//     }
//   }
//   return error;
// };

/**
 * Check if given email address already registered with different provider
 * @method checkExistingProviders
 * @param {Function} firebaseAuth fn as passed through our dependency middleware
 * @param {string} email address - that we are trying to authenticate with now
 * @param {string} provider - providerId that we are trying to authenticate with now
 */

export const checkExistingProviders = async (
  firebaseAuth: Auth,
  email: string,
  provider: string,
) => {
  const providers = await fetchSignInMethodsForEmail(firebaseAuth, email);
  if (providers.length && providers.indexOf(provider) < 0) {
    const error = {
      code: 'auth/account-exists-with-different-credential',
      provider: providers[0],
      email,
    };
    throw error;
  }
  return provider;
};

const facebookPermissions = ['email', 'public_profile'];

/**
 * Get appropriate firebase auth provider instance
 * @method getAuthProvider
 * @param {Object} firebaseAuth firebase auth instance
 * @param {providerId} providerId - provider type
 * @returns {Object} firebase provider instance
 */

export const getAuthProvider = (providerId: string) => {
  let provider = null;
  switch (providerId) {
    case AuthProviders.FACEBOOK:
      provider = new FacebookAuthProvider();
      provider.addScope(facebookPermissions.join(','));
      break;
    case AuthProviders.GOOGLE:
      provider = new GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/userinfo.email');
      break;
    case AuthProviders.APPLE:
      provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');
      break;
    default:
      provider = new GoogleAuthProvider();
  }
  return provider;
};

/**
 * Map provider type to provider string to be used in messages
 * @method mapProviderToString
 * @param {string} provider - provider as used in firebase
 * @returns {string} provider as used in messages
 */
export const mapProviderToString = (provider?: string | null) => {
  switch (provider) {
    case AuthProviders.FACEBOOK:
      return 'Facebook';
    case AuthProviders.GOOGLE:
      return 'Google';
    case AuthProviders.APPLE:
      return 'Apple';
    default:
      return 'Email';
  }
};
