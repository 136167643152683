import { SettingsAnalyticsActionTypes } from 'lib/settings/analytics/action-types';

export const triggerZendeskWidgetAnalytics = () => ({
  type: 'TRIGGERED_ZENDESK_WIDGET_ANALYTICS',
});

export const triggeredGetHelpWidgetAnalytics = () => ({
  type: 'TRIGGERED_GET_HELP_WIDGET_ANALYTICS',
});
export const triggerFeedbackFormAnalytics = () => ({
  type: 'TRIGGERED_FEEDBACK_FORM_ANALYTICS',
});

export const createMicrositeSuccessAnalytics = (payload: { id: string }) => ({
  type: SettingsAnalyticsActionTypes.CREATE_MICROSITE_SUCCESS_ANALYTICS,
  payload,
});

export const createMicrositeErrorAnalytics = (payload: { id: string; error: Error }) => ({
  type: SettingsAnalyticsActionTypes.CREATE_MICROSITE_ERROR_ANALYTICS,
  payload,
});

export const triggerDeleteMicrositeAnalytics = (payload: { id: string }) => ({
  type: SettingsAnalyticsActionTypes.TRIGGER_DELETE_MICROSITE_ANALYTICS,
  payload,
});

export const deleteMicrositeSuccessAnalytics = (payload: { id: string }) => ({
  type: SettingsAnalyticsActionTypes.DELETE_MICROSITE_SUCCESS_ANALYTICS,
  payload,
});

export const shareMicrositeAnalytics = (payload: { id: string }) => ({
  type: SettingsAnalyticsActionTypes.SHARE_MICROSITE_ANALYTICS,
  payload,
});

export const openAppVersionPopupAnalytics = () => ({
  type: SettingsAnalyticsActionTypes.OPEN_APP_VERSION_POPUP_ANALYTICS,
});

export const uploadProfilePhotoAnalytics = (payload?: { location: string }) => ({
  type: SettingsAnalyticsActionTypes.USER_UPLOAD_PROFILE_PHOTO_ANALYTICS,
  payload,
});

export const deleteProfilePhotoAnalytics = () => ({
  type: SettingsAnalyticsActionTypes.USER_DELETE_PROFILE_PHOTO_ANALYTICS,
});

export const clickedToUploadProfilePhotoAnalytics = (payload?: { location: string }) => ({
  type: SettingsAnalyticsActionTypes.USER_CLICKED_UPLOAD_PROFILE_PHOTO_ANALYTICS,
  payload,
});

export const clickedDeleteAccountAnalytics = () => ({
  type: SettingsAnalyticsActionTypes.USER_CLICKED_DELETE_ACCOUNT_ANALYTICS,
});

export const changedAppIconAnalytics = (payload: { selectedIconDesign: string }) => ({
  type: SettingsAnalyticsActionTypes.USER_CHANGED_APP_ICON_ANALYTICS,
  payload,
});

export const selectPredefinedProfilePhotoAnalytics = (payload: {
  predefinedPhotoLocation: string;
  predefinedPhotoTitle: string;
}) => ({
  type: SettingsAnalyticsActionTypes.USER_SELECTED_PREDEFINED_PROFILE_PHOTO,
  payload,
});

interface IClickedAnalyticsAction {
  cta: string;
  clickedSection: string;
  clickedLocation: string;
}

export const clickedAnalyticsAction = ({
  cta,
  clickedSection,
  clickedLocation,
}: IClickedAnalyticsAction) => ({
  type: SettingsAnalyticsActionTypes.CLICKED_ANALYTICS_ACTION,
  payload: { cta, clickedSection, clickedLocation },
});

export const triggeredInterceptPopupAnalytics = () => ({
  type: SettingsAnalyticsActionTypes.TRIGGERED_INTERCEPT_POPUP,
});
