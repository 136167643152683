import { AuthCredential, OAuthCredential } from 'firebase/auth';
import { IWedding } from '@bridebook/models/source/models/Weddings.types';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { Role } from 'lib/access-control/constants';
import { BridebookError } from 'lib/types';
import { IChangeAuthEmailForm } from './changeAuthEmailForm';
import { IForm } from './form';

export interface ICredentialsFields {
  email: string;
  password: string;
}

export interface IAuthFields extends ICredentialsFields {
  countryCode: CountryCodes;
}

// todo @yuri, fix last social provider using local storage
// export interface ILastSocialProvideState {
//   accessToken?: string;
//   currentProvider: string | null;
// }

export interface ICollaboratorInviteType {
  inviteError: string | null;
  inviteExpired: boolean;
  inviteUrl?: string;
  nonceId?: string;
  nonceSecret?: string;
  partners: IWedding['partners'];
  weddingId?: unknown;
  role: Role | null;
  method: 'askForFeedback' | null;
}

type PassChange = {
  email: string;
  oldPassword: string;
  continueUrl?: string;
};

export interface IAuthState {
  form: IForm;
  passChange: PassChange | null;
  signup: boolean;
  resetPassword: boolean;
  userTriggerAuth: boolean;
  protectedRoute: boolean;
  changeAuthEmailForm: IChangeAuthEmailForm;
  userId: string;
  emailExists: boolean;
  // todo @yuri, fix last social provider using local storage
  // lastSocialProvider: ILastSocialProvideState;
  emailExistsWithDifferentProvider: AuthProviders | null;
  emailAndPasswordFormShown: boolean;
  collaboratorInvite: ICollaboratorInviteType;
  updatedAccessControlThisSession: boolean;
  selectedCountryError: boolean;
  logOutAllDevices: boolean;
  deleteAccountInSession: boolean;
  loggedIn: boolean;
  authContextAndConditionalRedirectsReady: boolean;
}

export interface IFirebaseError {
  message: string;
  code: string;
  credential?: AuthCredential | OAuthCredential;
  email?: string;
}

export type AuthBridebookError = BridebookError & IFirebaseError & { provider?: AuthProviders };

export type CustomAuthErrorCodes = 'custom/api-authenticate-failed';
export type FirebaseErrorCodes =
  | 'auth/email-already-in-use'
  | 'auth/account-exists-with-different-credential'
  | 'auth/invalid-email'
  | 'auth/wrong-password'
  | 'auth/credential-already-in-use'
  | 'auth/user-not-found'
  | 'auth/network-request-failed'
  | 'auth/too-many-requests'
  | 'auth/user-token-expired'
  | 'auth/invalid-action-code'
  | 'auth/popup-closed-by-user';

export enum AuthProviders {
  FACEBOOK = 'facebook.com',
  GOOGLE = 'google.com',
  PASSWORD = 'password',
  APPLE = 'apple.com',
}
