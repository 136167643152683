import type { IUISupplier, TrackEventBody, TrackEventBodyWeb } from '@bridebook/toolbox/src/types';
import type { IApplicationState } from 'lib/types';
import getServerSideAnalyticsProps from 'lib/utils/get-server-side-analytics-props';

export interface IServerSideTrack extends TrackEventBody {
  state: IApplicationState;
  event: string;
  identifyProps?: Record<string, any>;
  specificEventProps?: Record<string, any>;
  contactedSupplier?: IUISupplier;
  bookedSupplier?: IUISupplier;
  category?: string;
}

const serverSideTrack = ({
  state,
  event,
  identifyProps,
  specificEventProps,
  contactedSupplier,
  bookedSupplier,
  category = '',
}: IServerSideTrack): Promise<any> => {
  const body: TrackEventBodyWeb = {
    event,
    identifyProps,
    genericProps: getServerSideAnalyticsProps(state),
    specificEventProps,
    source: 'web',
    contactedSupplier,
    bookedSupplier,
    category,
  };

  return fetch(`/api/a/t`, {
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  }).then((response) => {
    if (response.status !== 200) {
      throw new Error(response.statusText);
    }
    return response;
  });
};

export default serverSideTrack;
