import { UserActionTypes } from 'lib/users/action-types';

export const userFailedToEditUserDetailsAnalytics = (payload: {
  name?: unknown;
  value?: unknown;
  location?: unknown;
  error: unknown;
}) => ({
  type: UserActionTypes.USER_FAILED_TO_EDIT_USER_DETAILS_ANALYTICS,
  payload,
});
