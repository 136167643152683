import type { INotificationObject } from '@bridebook/toolbox/src/types';
import { AuthProviders } from 'lib/auth/types';
import { mapProviderToString } from 'lib/auth/utils/auth-provider-utils';
import { getI18n } from 'lib/i18n/getI18n';

/**
 * Map firebase error code to a user friendly message to be used on  notification
 * @method mapFBErrorToNotification
 * @param {Object} error from firebase
 * @param {string} provider - provider type
 * @returns {string} Notification message
 */
const mapFBErrorToNotification = (error: INotificationObject, provider: string): string => {
  // return error message when error.code is null or undefined
  const i18n = getI18n();

  if (error.code == null) {
    return error.message as string;
  }

  const providerString = mapProviderToString(provider);
  const alreadyInUseMessage =
    provider === AuthProviders.PASSWORD
      ? i18n.t('auth:invalid.facebook.email.inUse')
      : i18n.t('auth:invalid.facebook.email.associated', { providerString });

  const messages: Record<string, string> = {
    'auth/credential-already-in-use': alreadyInUseMessage,
    'auth/email-already-in-use': alreadyInUseMessage,
    'auth/wrong-password': i18n.t('auth:invalid.facebook.password.incorrect'),
    'auth/internal-error': i18n.t('auth:invalid.facebook.linkAccount.failed'),
    'auth/popup-closed-by-user': i18n.t('auth:invalid.popup.closed.by.user'),
  };

  return messages[error.code] || (error.message as string);
};

export default mapFBErrorToNotification;
